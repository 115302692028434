.footer-container {
  display: flex;
  max-height: 150px;
  align-items: end;
  justify-content: space-around;
  padding-bottom: 1rem;
}

.footer-container p {
  color: #6A3926;
}

.footer-container .footer-music {
  display: flex;
}

.footer-container .footer-music img {
  padding: 0 2px;
}

@media (max-width: 720px) {
  .footer-container {
    display: block;
    margin-bottom: 20px;
    text-align: center;
  }

  .footer-container img {
    max-width: 8rem;
  }

  .footer-container p {
    text-align: center;
    margin-bottom: 10px;
    font-size: .8rem;
  }

  .footer-container p span {
    font-size: .9rem;
  }

  .footer-container .footer-music {
    justify-content: center;
  }
}
