@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Work+Sans:wght@300&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --line-size: 4px;
  --symbol-width: 90px;
  --symbol-height: calc(var(--symbol-width) * 2);
  --background-1: #daa8cd;
  --background-2: #daa8cc;
  --background-3: #9696c8;
  --gradient: linear-gradient(var(--background-1),
      var(--background-2),
      var(--background-3));
  --neon-color: #fff;
  --neon-glow: #fff;
  --neon-shadow: 0 0 1rem var(--neon-color), inset 0 0 1rem var(--neon-color),
    0 0 2rem var(--neon-glow), inset 0 0 4rem var(--neon-glow),
    0 0 4rem var(--neon-glow);
  --shift: calc(var(--symbol-width) * -0.8);
}

.discography-title {
  color: #fff;
}

#container {
  position: relative;
  cursor: pointer;
  transition: all 0.5s ease;
}

#container:hover,
#container:hover #album {
  margin-left: var(--shift);
}

#container:hover #vinyl {
  margin-right: var(--shift);
}

#container:hover #tracklist {
  margin-left: calc(calc(100% + var(--shift)) + calc(var(--line-size) * 8));
}

#vinyl {
  position: absolute;
  width: calc(var(--symbol-height) * 1.45);
  height: calc(var(--symbol-height) * 1.45);
  border-radius: 50%;
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 15px rgba(255, 255, 255, 0.5);
  transition: all 0.5s ease;
}

#vinyl:before {
  width: 30%;
  height: 30%;
  border-radius: 50%;
  mix-blend-mode: multiply;
}

#vinyl:after {
  width: 3%;
  height: 3%;
  background: #000;
  border-radius: 50%;
}

#tracklist {
  height: calc(var(--symbol-height) - calc(var(--symbol-width) / 5));
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  white-space: nowrap;
  position: absolute;
  left: 0;
  transition: all 0.5s ease;
}

#tracklist li p {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
}

#tracklist li {
  font-size: calc(var(--line-size) * 3);
  margin-left: calc(var(--line-size) * 6);
  color: var(--neon-color);
  letter-spacing: calc(var(--line-size) * 1);
}

#tracklist li:before {
  width: calc(var(--line-size) * 8);
  text-align: center;
  display: inline-block;
  font-weight: 400;
  margin-left: calc(var(--line-size) * -5);
  transform-origin: bottom right;
}

#album {
  background-size: cover;
  background-repeat: no-repeat;
  width: calc(var(--symbol-height) * 1.5);
  height: calc(var(--symbol-height) * 1.5);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.15);
  position: relative;
  transition: all 0.5s ease;
}

.tracklist-title {
  font-size: 1rem;
}

.tracklist-artist {
  font-size: 0.8rem;
}

#title>div {
  display: flex;
  flex-direction: row;
  margin: calc(var(--line-size) * 2) 0;
}

#title .tracklist-artist {
  display: flex;
  flex-direction: row;
  margin-top: calc(var(--line-size) * 4);
}

#title .tracklist-artist h1 {
  color: #fff;
}

#title>div>h1 {
  font-size: calc(var(--line-size) * 16);
  line-height: 0.8;
}

#title>div>h1 span {
  transform: scaleX(0.7);
  width: calc(var(--symbol-width) / 5);
  display: flex;
  justify-content: center;
}

#title>div#artist>h1:first-of-type>span:nth-of-type(odd),
#title>div#artist>h1:nth-of-type(2)>span:nth-of-type(even),
#title>div#artist>h1:last-of-type>span:nth-of-type(even) {
  animation: bounce-down 1s linear infinite;
}

#title>div#artist>h1:first-of-type>span:nth-of-type(even),
#title>div#artist>h1:nth-of-type(2)>span:nth-of-type(odd),
#title>div#artist>h1:last-of-type>span:nth-of-type(odd) {
  animation: bounce-up 1s linear infinite;
}

@keyframes bounce-down {

  0%,
  100% {
    transform: scaleX(0.7) translateY(0);
  }

  50% {
    transform: scaleX(0.7) translateY(calc(var(--line-size) * 2));
  }
}

@keyframes bounce-up {

  0%,
  100% {
    transform: scaleX(0.7) translateY(0);
  }

  50% {
    transform: scaleX(0.7) translateY(calc(var(--line-size) * -2));
  }
}

#title>div#album-1>h1:first-of-type>span,
#title>div#album-1>h1:last-of-type>span {
  margin: 0 calc(calc(var(--symbol-width) / 5) * 0.5);
}

#title>div#album-1>h1:last-of-type {
  justify-content: flex-start;
}

#title>div#album-1>h1:first-of-type {
  justify-content: flex-end;
}

#title>div>h1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#title>div:not(:nth-of-type(2))>h1 {
  margin-right: calc(var(--symbol-width) / 5);
}

#title>div:not(:nth-of-type(2))>h1:last-of-type {
  margin-right: 0;
}

#title>div:not(:nth-of-type(2))>h1:nth-of-type(2) {
  width: calc(var(--symbol-width) - calc(calc(var(--symbol-width) / 5)) * 2);
}

#title>div#album-1>h1:nth-of-type(2) {
  width: calc(var(--symbol-width));
}

#title>div>h1:not(:nth-of-type(2)) {
  flex: 1;
}

#title>div#album-2>h1>span {
  font-size: calc(var(--line-size) * 18);
  transform-origin: center center;
}

#title>div#album-2>h1:first-of-type>span,
#title>div#album-2>h1:nth-of-type(2)>span:first-of-type {
  transform: scaleX(0.7) skewY(-18deg);
}

#title>div#album-2>h1:last-of-type>span,
#title>div#album-2>h1:nth-of-type(2)>span:last-of-type {
  transform: scaleX(0.7) skewY(18deg);
}

#symbol {
  width: var(--symbol-width);
  height: var(--symbol-height);
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  transform-origin: center center;
  transform: skewY(-18deg);
}

#symbol .line {
  width: var(--line-size);
  height: var(--symbol-height);
  background: var(--neon-color);
  position: relative;
  border-radius: var(--line-size);
  box-shadow: var(--neon-shadow);
}

#symbol .line p {
  position: absolute;
  color: var(--neon-color);
  right: 0;
  bottom: 0;
  white-space: nowrap;
  font-size: calc(var(--line-size) * 3.5);
  letter-spacing: calc(var(--line-size) * 0.8);
  line-height: 1;
  margin-bottom: calc(var(--line-size) * -6);
  opacity: 1;
  transition-delay: 0.25s;
}

#container:hover #symbol .line p {
  opacity: 0;
  transition: all 0.15s;
}

#symbol .line:before,
#symbol .line:after {
  box-shadow: var(--neon-shadow);
}

#container:hover #symbol>.line:last-of-type,
#symbol>.line:first-of-type,
#container:hover #symbol>.line:nth-of-type(2) {
  top: 0;
  height: calc(var(--symbol-height) - calc(var(--symbol-width) / 5));
}

/* SETUP */
#symbol>.line:first-of-type:after,
#symbol>.line:last-of-type:after {
  height: var(--line-size);
  bottom: 0;
}

#symbol>.line:first-of-type:before,
#symbol>.line:last-of-type:before,
#symbol>.line:nth-of-type(5):before {
  height: var(--line-size);
  width: calc(var(--symbol-width) / 5);
  top: 0;
}

#symbol>.line:nth-of-type(5):before {
  right: calc(calc(var(--symbol-width) / 5) * 1);
}

#symbol>.line:last-of-type:before,
#symbol>.line:last-of-type:after,
#symbol>.line:nth-of-type(3):before {
  right: 0;
}

/* FLAT */

#container:hover #symbol>.line:first-of-type:after,
#symbol>.line:last-of-type:after {
  transform: rotate(0deg);
  width: 0;
}

/* ANGLED */

#symbol>.line:first-of-type:after,
#container:hover #symbol>.line:last-of-type:after,
#symbol>.line:nth-of-type(4):before,
#container:hover #symbol>.line:nth-of-type(3):before {
  width: calc(calc(var(--symbol-width) / 5) * 1.4);
  height: var(--line-size);
  bottom: 0;
}

#symbol>.line:first-of-type:after,
#symbol>.line:nth-of-type(4):before {
  transform-origin: bottom left;
  transform: rotate(45deg);
}

#container:hover #symbol>.line:last-of-type:after,
#container:hover #symbol>.line:nth-of-type(3):before {
  transform-origin: bottom right;
  transform: rotate(-45deg);
  right: 0;
}

#container:hover #symbol>.line:nth-of-type(4):before,
#container:hover #symbol>.line:nth-of-type(4):after,
#symbol>.line:nth-of-type(3):after,
#symbol>.line:nth-of-type(3):before {
  width: 0;
  height: var(--line-size);
  bottom: 0;
}

#container:hover #symbol>.line:nth-of-type(3):after,
#symbol>.line:nth-of-type(4):after {
  width: calc(var(--symbol-width) / 5);
  height: var(--line-size);
  bottom: 0;
}

#container:hover #symbol>.line:nth-of-type(3):after {
  transform-origin: bottom left;
  left: 0;
}

#symbol>.line:nth-of-type(4):after {
  right: 0;
  transform-origin: bottom right;
}

#container:hover #symbol {
  transform: skewY(18deg);
}

#symbol>.line:nth-of-type(3),
#symbol>.line:nth-of-type(5),
#container:hover #symbol>.line:nth-of-type(4) {
  height: calc(var(--symbol-height) - calc(var(--symbol-width) / 5));
}

#symbol>.line:nth-of-type(4),
#container:hover #symbol>.line:nth-of-type(3) {
  height: calc(var(--symbol-height) - calc(calc(var(--symbol-width) / 5)) * 2);
}

#container:hover #symbol>.line:nth-of-type(5),
#container:hover #symbol>.line:first-of-type {
  height: var(--symbol-height);
}

#symbol>.line:nth-of-type(2):after,
#container:hover #symbol>.line:nth-of-type(5):after {
  height: var(--line-size);
  width: calc(calc(var(--symbol-width) / 5) * 4);
  bottom: 0;
}

#container:hover #symbol>.line:nth-of-type(5):after,
#symbol>.line:nth-of-type(5):after {
  right: 0;
}

#symbol>.line:nth-of-type(5):after,
#container:hover #symbol>.line:nth-of-type(2):after {
  height: var(--line-size);
  width: calc(calc(var(--symbol-width) / 5) * 2);
  bottom: 0;
}

#symbol .line .shadow {
  position: absolute;
  width: calc(calc(var(--symbol-width) / 5) - calc(var(--line-size) * 1));
  display: flex;
  top: 0;
  margin-left: var(--line-size);
  margin-top: var(--line-size);
  transform-origin: bottom left;
  padding: 0;
  height: calc(var(--symbol-height) - calc(var(--symbol-width) / 5));
  transition: opacity 0.25s ease;
}

#symbol .line .shadow:before {
  top: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
  transform-origin: bottom left;
}

#symbol .line .shadow:after {
  bottom: 0;
  transform-origin: bottom left;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
}

#container:hover #symbol .line .shadow {
  transition: opacity 0.25s ease 0.5s;
}

#container:hover #symbol .line .shadow:before,
#container:hover #symbol .line .shadow:after,
#symbol .line:nth-of-type(4) .shadow:after,
#symbol .line:first-of-type .shadow:after {
  transition: opacity 0.5s ease 0.5s;
}

#symbol .line .shadow:before,
#symbol .line .shadow:after,
#container:hover #symbol .line:nth-of-type(4) .shadow:after,
#container:hover #symbol .line:first-of-type .shadow:after {
  width: inherit;
  height: 50%;
  transition: opacity 0.25s ease;
}

#symbol .line:nth-of-type(4) .shadow,
#symbol .line:first-of-type .shadow {
  transform: skewY(45deg);
}

#symbol .line:nth-of-type(5) .shadow:after,
#symbol .line:nth-of-type(2) .shadow,
#symbol .line:first-of-type .shadow:before {
  transform: skewY(-45deg);
}

#symbol .line:first-of-type .shadow {
  height: calc(calc(var(--symbol-height) - calc(calc(var(--symbol-width) / 5)) * 1) - calc(var(--line-size) * 1.5));
}

#symbol .line:nth-of-type(4) .shadow {
  height: calc(calc(var(--symbol-height) - calc(calc(var(--symbol-width) / 5)) * 2) - calc(var(--line-size) * 1.5));
}

#symbol .line:nth-of-type(5) .shadow {
  height: calc(var(--symbol-height) - calc(var(--line-size) * 1.5));
}

#container:hover #symbol .line:nth-of-type(2) .shadow:after,
#container:hover #symbol .line:nth-of-type(5) .shadow:after {
  opacity: 1;
}

#symbol .line:nth-of-type(2) .shadow {
  height: calc(calc(var(--symbol-height) - calc(calc(var(--symbol-width) / 5)) * 1) - calc(var(--line-size) * 1.5));
}

#symbol .line:nth-of-type(3) .shadow:after,
#symbol .line:nth-of-type(4) .shadow:before,
#symbol .line:nth-of-type(2) .shadow:before,
#symbol .line:nth-of-type(2) .shadow:after,
#container:hover #symbol .line:first-of-type .shadow:after,
#symbol .line:nth-of-type(5) .shadow:after,
#container:hover #symbol .line:nth-of-type(4) .shadow:after,
#container:hover #symbol .line:first-of-type .shadow:after {
  opacity: 0;
}

h2#cta {
  bottom: calc(var(--line-size) * 5);
  position: absolute;
  height: auto;
}

h2#cta a {
  text-decoration: none;
  letter-spacing: calc(var(--line-size) * 0.8);
  color: var(--neon-color);
  font-size: calc(var(--line-size) * 4);
  white-space: nowrap;
}

h2#cta a::after {
  margin-left: 6px;
  display: inline-block;
  content: "\0279E";
  transition: transform 0.15s ease-in;
}

h2#cta a:hover::after {
  transform: translateX(3px);
}

.discography-title p:nth-child(1) {
  max-width: 200px;
  letter-spacing: 3px;
}

.discography-title p:nth-child(2) {
  font-size: 0.8rem;
  letter-spacing: 2px;
}

@media (max-width: 700px) {
  :root {
    --symbol-width: 80px;
    --symbol-height: calc(var(--symbol-width) * 1.7);
  }

  #tracklist {
    width: calc(var(--symbol-height) + calc(var(--symbol-width) / 5));
  }

  .tracklist-title, .tracklist-artist {
    font-size: 8px;
  }

  #container:hover #tracklist {
    margin-left: calc(calc(100% + var(--shift)) + calc(var(--line-size) * 4));
  }

}
