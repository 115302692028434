.loading-container {
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #1c1c1d;
}

.loading-container-line {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  width: 5rem;
  height: 4rem;
  overflow: hidden;
}

.loading-container-line .loading-line {
  display: flex;
  width: 0.2rem;
  height: 1rem;
  background-color: #B16A4E;
  border-radius: 1px;
  animation: loading-animation 1.8s ease-in-out infinite;
}

.loading-container .loading-text {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: #B16A4E;
}

.loading-line:nth-child(1) {
  animation-delay: 0s;
}

.loading-line:nth-child(2) {
  animation-delay: 0.1s;
}

.loading-line:nth-child(3) {
  animation-delay: 0.2s;
}

.loading-line:nth-child(4) {
  animation-delay: 0.3s;
}

.loading-line:nth-child(5) {
  animation-delay: 0.4s;
}

.loading-line:nth-child(6) {
  animation-delay: 0.5s;
}

.loading-line:nth-child(7) {
  animation-delay: 0.6s;
}

@keyframes loading-animation {
  0% {
    transform: scaleY(1)
  }

  50% {
    transform: scaleY(6)
  }

  100% {
    transform: scaleY(1)
  }
}