.banner-container {
  height: 80vh;
  display: grid;
  place-items: center;
  overflow: hidden;
}

.banner-main {
  position: relative;
  width: 100%;
  height: 100%;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
}

.banner-item {
  width: 7.4rem;
  height: 9rem;
  list-style-type: none;
  position: absolute;
  top: 78%;
  right: 0;
  transform: translateY(-50%);
  z-index: 1;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 20px;
  box-shadow: 0 20px 30px rgba(255, 255, 255, 0.3) inset;
  transition: transform 0.1s, left 0.75s, top 0.75s, width 0.75s, height 0.75s;

  &:nth-child(1), &:nth-child(2) {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: none;
    border-radius: 0;
    box-shadow: none;
    opacity: 1;
  }

  &:nth-child(3) {left: 70%;}
  &:nth-child(4) { left: calc(70% + 140px); }
  &:nth-child(5) { left: calc(70% + 280px); }
  &:nth-child(6) { left: calc(70% + 420px); }
}

.content {
  width: min(100vw, 600px);
  position: absolute;
  top: 80%;
  left: 8%;
  transform: translateY(-50%);
  color: white;
  text-shadow: 0 3px 8px rgba(0, 0, 0, 0.5);
  opacity: 0;
  display: none;

  & .title {
    text-transform: uppercase;
    font-size: 2.1rem;
    letter-spacing: 8px;
    margin: 0;
  }

  & .title span {
    font-size: 1.4rem;
  }

  & .description {
    font-size: 1.2rem;
    color: var(--orange);
    line-height: 1.7;
    letter-spacing: 1.5px;
    margin: 10px auto;
  }

  & a {
    display: block;
    width: fit-content;
    background-color: rgba(0, 0, 0, 0.1);
    color: white;
    border: 2px solid white;
    border-radius: 0.25rem;
    padding: 0.75rem;
    cursor: pointer;
  }

  & a:hover {
    background-color: rgba(255, 255, 255, 0.2);
    background-position: 50%;
    color: var(--orange);
  }
}

.banner-item:nth-of-type(2) .content {
  display: block;
  animation: show 0.75s ease-in-out 0.3s forwards;
}

@keyframes show {
  0% {
    filter: blur(5px);
    transform: translateY(calc(-50% + 75px));
  }
  100% {
    opacity: 1;
    filter: blur(0);
  }
}

.nav {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  user-select: none;

  & .btn {
    background-color: transparent;
    border: none;
    margin: 0 0.5rem;
    padding: 0.75rem;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      transform: scale(1.2);
      transition: 0.3s ease-in;
    }
  }
}

.prev {
  background-image: url('../assets/icons/arrow_prev.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.next {
  background-image: url('../assets/icons/arrow_next.svg');
  background-repeat: no-repeat;
  background-position: center;
}


@media screen and (max-width: 1200px) {
  .banner-container {
    height: 70vh;
  }
}

@media screen and (max-width: 899px) {

  .content {
    top: 60%;
    & .title {
      font-size: 1.5rem;
    }

    & .title span {
      font-size: 1rem;
    }

    & .description {
      font-size: 1rem;
    }
  }

  .banner-item {
    &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6) {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: none;
        border-radius: 0;
        box-shadow: none;
        opacity: 0;
      }
  }
}
