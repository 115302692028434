.header-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-height: 100px;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
}

.image-logo img {
  width: 220px;
  height: 100px;
  max-width: 200px;
}

.burger {
  display: none;
  border: 0;
  padding: 0;
  background: transparent;
  cursor: pointer;
  outline: none;
}

.header-container .header-menu nav ul {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  height: 100%;
}

.header-container .header-menu nav ul li {
  text-align: center;
  padding: 10px 5px;
  margin: 0 5px;
  position: relative;
}

.header-container .header-menu nav ul li a.active {
  color: var(--orange);
  text-decoration: underline;
}

.header-container .header-menu nav ul li a {
  text-transform: uppercase;
  font-size: 1rem;
  text-decoration: none;
  color: white;
}


.header-container .header-menu nav ul li a::before {
  transform: scaleX(0);
  transform-origin: bottom right;
  color: transparent;
}

.header-container .header-menu nav ul li a:hover::before {
  transform: scale(1);
  transform-origin: bottom left;
  color: transparent;
}

.header-container .header-menu nav ul li a:hover {
  transform: scale(1);
  transition: transform 70ms, filter 70ms ease-in;
}

.header-container .header-menu nav ul li a::before {
  content: " ";
  display: block;
  position: absolute;
  inset: 0 0 0 0;
  background-color: rgba(240, 143, 105, 0.3);
  transition: transform .3s ease;
  cursor: pointer;
  border-radius: 10px;
}

@media screen and (max-width: 1024px) {

  .header-container {
    position: relative;
    height: 100px;
  }

  .image-logo {
    position: absolute;
    top: 0;
    left: 30px;
  }

  .image-logo.open {
    z-index: 3;
  }

  .burger {
    display: grid;
    z-index: 4;
    top: 0;
    right: 30px;
    display: grid;
    place-items: center;
    width: 88px;
    height: 88px;
    background: url("../assets/icons/menu.svg");
    background-repeat: no-repeat;
    background-position: center;
  }

  .burger.open {
    background-image: url('../assets/icons/close.svg');
  }

  .burger,
  .header-menu {
    position: fixed;
  }

  .header-background {
    position: fixed;
    z-index: 2;
    top: 44px;
    left: 0%;
    aspect-ratio: 1 / 1;
    translate: -20vh -165vh;
    height: 88px;
    background: #07030a;
    border-radius: 50%;
    opacity: 0;
    transition: 0.6s;
  }

  .header-background.open {
    height: 300vh;
    opacity: 0.85;
  }

  .header-container .header-menu {
    z-index: 3;
    top: 120px;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transition: 0.05s;
  }

  .header-container .header-menu nav ul {
    align-items: end;
    justify-content: start;
    width: 90%;
  }

  .header-menu a {
    transition: 0.4s;
  }

  .header-menu nav ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 100px;
  }

  .header-menu.open {
    right: 110px;
    /* background-color: rgb(0, 0, 0); */
    opacity: 1;
    visibility: visible;
  }

  .header-menu.open nav ul li a {
    font-size: 1.5rem;
  }

  .header .header-menu nav ul:hover li > a {
    opacity: 0.25;
  }

  .header .header-menu nav ul li > a:hover {
    opacity: 1;
  }

  .header .header-menu nav ul li > a {
    animation-delay: 0.1s;
  }

  @keyframes appear {
    0% {
      opacity: 0;
      translate: 0 30px;
    }

    100% {
      opacity: 1;
    }
  }

  .appear {
    animation: appear 0.35s backwards;
  }

  .page {
    padding: 100px 15%;
    transition: 0.6s;
  }

  .page.open {
    filter: blur(10px);
  }

  .page h2 {
    font-weight: 400;
  }

  .page p {
    line-height: 1.66;
    opacity: 0.6;
  }

}
