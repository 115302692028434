section.promotion-container {
  position: relative;
  height: 100vh;
}

.promotion-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: brightness(0.68);
}

.promotion-info {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 70%;
  margin-left: 10vw;
}

.promotion-info .info-container {
  display: flex;
  align-items: end;
}

.promotion-info .info-container div {
  padding-bottom: 1.5rem;
}

.promotion-info img {
  filter: brightness(0.70);
  border-radius: 25px;
  margin: 0 40px;
}

.promotion-info div p {
  margin-bottom: 20px;
  color: #FFF;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  font-size: 1.3rem;
}

.promotion-info div a {
  color: #FFF;
  font-size: 1rem;
  padding: 0.8rem 1rem;
  margin-top: 1rem;
  background-color: transparent;
  border: 1px solid #FFF;
  border-radius: 20px;
}

@media (max-width: 720px) {
  .promotion-info .info-container {
    display: block;
  }

  .promotion-info .info-container img {
    max-width: 15rem;
    margin: 0 auto;
  }
}
