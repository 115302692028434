.chords-image {
  position: relative;
  height: 50vh;
  width: 100%;
  background-image: url("../assets/img/tutorial/portrait-chords.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.chords-album-section {
  position: absolute;
  bottom: -40px;
  left: 20%;
  width: 20%;
}

.chords-album-container {
  width: 10rem;
  height: 10rem;
  background-color: #FFF;
  border-radius: 3%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chords-album {
  width: 95%;
  height: 95%;
  text-align: center;
  border-radius: 5%;
}

.chords-album-title {
  position: absolute;
  bottom: 30%;
  left: 38%;
  color: var(--orange);
  font-weight: normal;
  text-transform: uppercase;
  font-size: 1.5rem;
}

.chords-columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  width: 75%;
  padding: 5% 0;
  margin: 0 auto;
}

.chords-list h2 {
  color: var(--orange);
  font-size: 2rem;
  font-weight: normal;
  margin: 40px 0;
}

.chords-mobile-title {
  display: none;
}

.chords-details {
  display: flex;
  justify-content: space-between;
  max-width: 60%;
}

.chords-detail {
  padding: 10px 18px;
  border-radius: 25px;
  background-color: #B16A4E;
  color: #fff;
  font-size: 1.2rem;
}

.chords-list-title, .chords-details {
  margin-bottom: 30px;
}

.chords-list-title a {
  width: fit-content;
}

.chords-list-title h3 {
  color: #B16A4E;
  font-size: 1.5rem;
  margin-left: 2rem;
}

.selected {
  color: #B16A4E;
  text-decoration: underline;
}

.chords-title-song {
  display: block;
  margin: 5px;
  font-size: 1.2rem;
  text-transform: capitalize;
}

.chords-listen {
  display: block;
  margin: 30px 0;
  padding: 9px;
  font-size: 1.8rem;
  color: #FFF;
  border-radius: 5px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.25);
  width: 50%;
  background-color: #B16A4E;
}

.chords-listen:hover {
  transform: scale(1.03);
  transition: all ease 1s;
}

.chords-soon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
}

@media (max-width: 1600px) {
  .chords-columns {
    width: 90%;
  }
}

@media (max-width: 1300px) {
  .chords-columns {
    display: block;
  }

  .chords-details {
    width: 100%;
  }

  .chords-listen {
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 800px) {

  .chords-desktop-title {
    display: none;
  }

  .chords-mobile-title {
    display: block;
  }

  .chords-album-title {
    display: none;
  }

  .chords-details {
    max-width: 100%;
  }

  .chords-detail {
    font-size: 1rem;
  }

  .chords-video {
    width: 100%;
    height: 15rem;
  }


}
